import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';

const skillsArr = ['CSS', 'Javascript', 'HTML', 'SCSS', 'React JS', 'Node JS', 'Gatsby JS', 'Wordpress', 'Sanity', 'Git', 'Graphql', 'Styled Components', 'npm'];

const BioStyles = styled.div`

  padding: 6rem 0;

  .headline {
    font-size: 2.1rem;
    @media (max-width: 635px) {
      font-size: 1.8rem;
    }
  }

`;

const TechStyles = styled.div`

  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 3rem;
  padding: 3.5rem 0;
  margin-bottom: 3.5rem;

  margin-left: ${ props  => props.isSafari ? '-1.5rem' : '0'};
  margin-right: ${ props  => props.isSafari ? '-1.5rem' : '0'};

  @media (max-width: 635px) {
      gap: 2rem;
    }

  .item {
    background-color: var(--color-green-3);
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    padding: .75rem 1.5rem;
    font-size: 2.4rem;
    border-radius: 5px;
    opacity: 0;
    transition: all .3s ease;
    /* transform: translate(0, -20px); */
    animation: fade-in 1s ease forwards;
    @media (max-width: 635px) {
      font-size: 1.8rem;
    }

    &:last-child {
      margin-right: auto;
    }

    &:nth-child(3n + 1) {
      background-color: var(--color-blue-1);
    }

    &:nth-child(3n + 2) {
      background-color: var(--color-red-1);
    }

    &.safari-fallback {
      margin: 1.5rem;
        &:last-child {
        margin-right: auto;
      }
    }
  }

`;

const SkillStyles = styled.ul`

  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  margin-bottom: 4.5rem;

  li {
    flex: 1 0 50%;
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    font-weight: 300;
    padding-right: 1rem;
    @media (max-width: 765px) {
      font-size: 1.4rem;
    }

    &::before {
      display: inline-block;
      content: '';
      border-radius: 0.375rem;
      height: 0.75rem;
      width: 0.75rem;
      margin-right: 1.5rem;
      background-color: #bdbdbd;
    }

    @media (max-width: 765px) {
      flex: 1 0 100%;
    }
  }

`;

export default function Home({ location, data }) {
  const technology = data.technology.nodes;

  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    var isSafari = window.safari !== undefined;
    if (isSafari) setIsSafari(true);
  }, []);

  return (
    <>
      {/* eslint-disable-next-line */}
      <SEO location={location} title="Home" description="I’m Nikolas. I’m a web developer. I live and work in Minneapolis, MN. I have a genuine curiosity for web technologies, and am deeply involved in learning new methods and practices to better my designs and digital creations." />
      <BioStyles>
        <p className="headline">
          I’m <span className="highlight">Nikolas</span>. I’m a web developer. I live and work in Minneapolis, MN. I have a genuine curiosity for web technologies, and am deeply involved in learning new methods and practices to better my designs and digital creations.
        </p>
      </BioStyles>
      <div className="sub-heading">
        <div className="pixel"></div>
        <h2>TECHNOLOGY STACK</h2>
      </div>
      <TechStyles isSafari={isSafari} role="list">
        {technology.map((tech, index) => (
          <div key={tech.id} className={isSafari ? 'item safari-fallback' : 'item'} style={{ animationDelay: `${index * 100}ms` }} role="listitem" aria-label={index}>{tech.name}</div>
        ))}
        {/* {skillsArr.map((skill, index) => (
          <div key={index} className={isSafari ? 'item safari-fallback' : 'item'} style={{ animationDelay: `${index * 100}ms` }} role="listitem" aria-label={index}>{skill}</div>
        ))} */}
      </TechStyles>
      <div className="sub-heading">
        <div className="pixel"></div>
        <h2>UX / UI</h2>
      </div>
      <SkillStyles>
        <li>Usability and accessiblity standards</li>
        <li>Design / mockups in Adobe CC and Figma</li>
        <li>Responsive design across devices</li>
        <li>Modular and scalable stylesheets</li>
        <li>Cross browser compatibility</li>
      </SkillStyles>
      <div className="sub-heading">
        <div className="pixel"></div>
        <h2>SERVICES</h2>
      </div>
      <SkillStyles>
        <li>Web design &amp; development</li>
        <li>Site maintenance</li>
        <li>SEO and analytics</li>
        <li>E-commerce</li>
        <li>Deployment and hosting</li>
      </SkillStyles>
    </>
  )
}

export const query = graphql`
  query {
    technology: allSanityTechnology {
      nodes {
        name
        id
      }
    }
  }
`;